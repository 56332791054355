<template lang="pug">
  div.tenant-form.mx-auto
    v-card.pa-6
      v-card-title
        v-icon(color="primary" size="32") group
        h3.primary--text.ml-2 Mandant wählen

      v-form.mt-3(ref="tenant-form" lazy-validation)
        p.mb-4 Bitte wählen sie den Mandanten aus, welchen Sie bearbeiten möchten

        v-select(
          v-model="tenantId"
          label="Mandanten"
          dense
          outlined
          :items="tenants"
          :item-text="`text`"
          :item-value="`id`"
          :rules="[ rules.required ]"
        )

        v-row.mt-3
          v-col(cols="12")
            v-btn(block color="primary" :disabled="!tenantId" @click="onSelectTenant") Wählen

</template>

<script>
import { Vue, Component } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    FormMixin
  ],

  computed: mapGetters([
    'userData'
  ]),
})
export default class Tenant extends Vue {
  tenantId = ''

  get tenants () {
    if (!this.userData || !this.userData.tenants) {
      return []
    }

    return this.userData.tenants.map(t => ({
      id: t.id,
      name: t.name,
      name2: t.name2,
      text: t.name2 ? `${ t.name } ${ t.name2 } - (${ t.city })` : `${ t.name } - (${ t.city })`,
      user_group: t.user_group,
      short_code: t.short_code,
      city: t.city
    }))
  }

  onSelectTenant () {
    if (!this.isFormValid('tenant-form')) {
      return
    }

    const tenant = this.tenants.find(t => t.id === this.tenantId)
    this.selectTenant(tenant)
      .then(() => {
        this.$store.dispatch('setUserGroup', tenant.user_group)
        this.gotoRoute('admin-home')
      })
  }
}
</script>

<style scoped>
.tenant-form {
  margin-top: 150px;
  max-width: 450px;
  width: 100%;
}
</style>
