import moment from 'moment'
import config from '@/config'
import rules from '@/utils/rules'
import Urls from '@/router/urls'

import { mapActions, mapGetters } from 'vuex'
import { downloadBlob } from '@/utils/download'
import { formatNumber } from '@/utils/number'

export default {
  data: () => ({
    rules,

    tableProps: {
      'no-data-text': 'Keine Daten vorhanden',
      'no-results-text': 'Keine Daten gefunden',
      'footer-props': {
        'items-per-page-options': [5, 15, 50, 200, 500, 2000],
        'items-per-page-text': 'Anzahl pro Seite',
        'pagination': {
          itemsLength: 0,
          pageStart: 0,
          pageStop: 0
        },
      }
    },

    dataOptions: {
      page: 1,
      itemsPerPage: 50,
      sortBy: [ 'id' ],
      sortDesc: [ true ],
      multiSort: false,
    },

    defaultPagination: {
      page: 1,
      itemsPerPage: 50
    },

    countries: [
      'DE',
      'AT',
      'CH'
    ],

    salutations: [
      'Herr',
      'Frau'
    ]
  }),

  computed: {
    ...mapGetters([
      'getPageFilters',
      'selectedTenant'
    ]),
  },

  methods: {
    ...mapActions([
      'selectTenant',
      'setIsLoading',
      'setPageFilter'
    ]),

    base64toBlob (b64Data, contentType = '', sliceSize = 512) {
      const byteCharacters = atob(b64Data)
      const byteArrays = []

      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize)
        const byteNumbers = new Array(slice.length)

        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i)
        }

        byteArrays.push(new Uint8Array(byteNumbers))
      }

      return new Blob(byteArrays, { type: contentType })
    },

    changeDateFormat (value, format1 = 'DD.MM.YYYY', format2 = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') {
      const date = moment(value, format1)

      return date.isValid()
        ? date.format(format2)
        : null
    },

    downloadCard (id) {
      this.$axios
        .get(Urls.Card.DownloadCard, {
          order_id: id,
          from_archive: this.fromArchive,
        }, true)
        .then(res => {
          downloadBlob(res.data, this.getFileNameFromHeader(res.headers))
        })
        .catch(err => {
          this.$toast.error('Fehler', err.message || 'Failed to download card')
        })
    },

    recreateCard (id) {
      this.$axios
        .post(Urls.Card.RecreateCard, {
          order_id: id,
          from_archive: this.fromArchive,
        }, false)
        .then(res => {
          this.$toast.success('Card recreated successfully.')
        })
        .catch(err => {
          this.$toast.error('Fehler', err.message || 'Failed to download card')
        })
    },

    formatDateTime (value, format = 'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]') {
      const date = moment(value)

      return date.isValid()
        ? date.format(format)
        : null
    },

    formatMoney (value, decimals = 2, currency = '€') {
      if (!value && value !== 0) {
        return ''
      }

      return `${ formatNumber(value, decimals) } ${ currency }`
    },

    getDateFromOffset (from, format, offset, unit = 'days') {
      if (!from) {
        return ''
      }

      const fromDate = moment(from, format)
      if (!fromDate.isValid()) {
        return ''
      }

      return fromDate.add(offset, unit).format(format)
    },

    getFileNameFromHeader (header) {
      const contentDisposition = header['content-disposition']

      if (!contentDisposition) {
        return ''
      }

      const match = contentDisposition.match(/filename=(.*)/)

      return match && match.length > 1
        ? match[1].replace(/"/g, '')
        : ''
    },

    getHashString () {
      return moment().valueOf()
    },

    getImagePath (filePath) {
      if (!filePath) {
        return ''
      }
      return `${ config.IMAGE_BASE_URL }${ filePath }?${ this.getHashString() }`
    },

    gotoOrderPage (productShortCode) {
      const route = this.$router.resolve({
        name: 'ordering',
        params: {
          tenantShortCode: this.selectedTenant.short_code,
          productShortCode
        }
      })

      window.open(route.href, '_blank')
    },

    gotoRoute (name, params = null) {
      if (!name || this.$route.name === name) {
        return
      }
      const route = {
        name,
      }
      if (params) {
        route.params = params
      }
      this.$router.push(route)
    },

    openRoute (name, params = null) {
      if (!name || this.$route.name === name) {
        return
      }
      const route = {
        name,
      }
      if (params) {
        route.params = params
      }
      const route_ = this.$router.resolve(route)
      window.open(route_.href, '_blank')
    },

    isAfter (from, to, format = 'DD.MM.YYYY') {
      if (!from || !to) {
        return false
      }

      const dateFrom = moment(from, format)
      if (!dateFrom.isValid()) {
        return false
      }
      const dateTo = moment(to, format)
      if (!dateTo.isValid()) {
        return false
      }
      return dateFrom.isAfter(dateTo)
    },

    isFormValid (ref) {
      const form_ = this.$refs[ref]
      return form_ && form_.validate()
    },

    resetForm (ref) {
      const form_ = this.$refs[ref]
      if (form_) {
        form_.resetValidation()
      }
    },
  }
}
