<template lang="pug">
  .product-details
    .action-header
      v-btn(v-if="isUpdated" color="primary" @click="onSaveProduct") Speichern

    v-card.pa-6: v-form(ref="form-product" lazy-validation)
      v-row.align-center
        v-col(sm="8")
          h2.primary--text {{ title }}

        v-col.admin-view.text-right(v-if="isSuperAdmin && id" sm="4")
          a(@click.prevent="gotoOrderPage(productShortCode)")
            span Zur Bestellseite
            v-icon.ml-1(color="primary") arrow_forward

      v-row.justify-center
        template(v-for="(image, index) in images"): v-card.d-inline-block.my-4.mx-8(
          v-if="image.needs_file"
          :key="index"
        )
          product-image(
            :file="image.src"
            :type="getImageType(image)"
            :width="getImageWidth(image)"
            :height="getImageHeight(image)"
            @select="(e) => onSelectImage(index, e)"
          )
            template(#action-text)
              span.mr-2 {{ getImageLabel(image.id) }}
              info-tooltip.image-hint(align="center") Ideale Bildgröße Breite {{ getImageWidth(image) }} Pixel und Höhe {{ getImageHeight(image) }} Pixel

      v-row.my-6
        v-col.pb-0(cols="12" sm="6"): v-text-field(
          v-model="product.id"
          label="ID"
          dense
          outlined
          disabled
        )
        v-col.pb-0(v-if="isSuperAdmin && product.product_id" cols="6" sm="2"): v-text-field.admin-input(
          v-model="product.max_years_valid"
          label="MaxJahreGültig"
          type="number"
          dense
          outlined
          :rules="[ rules.required ]"
        )

        v-col.pb-0(v-if="isSuperAdmin && product.product_id" cols="6" sm="2"): v-text-field.admin-input(
          v-model="product.days_before_renew"
          label="Anz. Tage vor Ablauf"
          type="number"
          dense
          outlined
        )


        // v-col.pb-0(cols="12" sm="6")

        v-col.pb-0(cols="12" sm="6"): v-select(
          v-model="product.product_id"
          label="Produkt ID"
          outlined
          dense
          :items="availableProducts"
          :item-text="`description`"
          :item-value="`product_id`"
          :rules="[ rules.required ]"
          @change="(v) => onSelectProduct(v)"
        )

        template(v-if="product.product_id")
          v-col.pb-0(cols="12" sm="6"): v-text-field(
            v-model="product.product_short_code"
            label="Produktcode"
            dense
            outlined
            :disabled="!!id"
            :rules="[ rules.required ]"
          )

          v-col.pt-1(cols="6" sm="3"): v-checkbox.mt-0(
            v-model="product.can_online"
            label="Online möglich?"
            hide-details
          )

          v-col.pt-1(cols="6" sm="3")
            v-checkbox.mt-0(
              v-model="product.can_hardcopy"
              label="Karte möglich?"
              hide-details
              @change="(v) => onChangeHardCopy(v)"
            )
            .d-flex.align-center(v-if="product.can_hardcopy")
              v-icon.ml-2 subdirectory_arrow_right
              v-checkbox.mt-0(
                v-model="product.export_unvalidated"
                label="Unvalidierte Karten verschicken?"
                hide-details
              )

          v-col.pb-0(cols="12" sm="3"): v-text-field(
            v-model="product.tenant_name"
            label="Name"
            dense
            outlined
          )

          v-col.pb-0(cols="12" sm="3"): v-text-field(
            v-model="product.tenant_name2"
            label="Name2"
            dense
            outlined
          )

          v-col.pb-0(v-if="isSuperAdmin" cols="4" sm="2"): v-text-field.admin-input(
            v-model="product.price_online"
            label="Preis Online"
            type="number"
            dense
            outlined
            :rules="[ rules.required ]"
          )

          v-col.pb-0(v-if="isSuperAdmin" cols="4" sm="2"): v-text-field.admin-input(
            v-model="product.price_hardcopy"
            label="Preis Karte"
            type="number"
            dense
            outlined
            :rules="[ rules.required ]"
          )

          v-col.pb-0(v-if="isSuperAdmin" cols="4" sm="2"): v-text-field.admin-input(
            v-model="product.price_hardcopy_online"
            label="Preis Online+Karte"
            type="number"
            dense
            outlined
            :rules="[ rules.required ]"
          )

          v-col.pb-0(v-if="isSuperAdmin" cols="4" sm="2"): v-checkbox.admin-input.mt-0(
            v-model="product.online_free"
            label="Online free?"
            hide-details
          )

          v-col.pb-0(v-if="isSuperAdmin" cols="4" sm="2"): v-checkbox.admin-input.mt-0(
            v-model="product.hardcopy_free"
            label="Karte free?"
            hide-details
          )

          v-col.pb-0(v-if="isSuperAdmin" cols="4" sm="2"): v-checkbox.admin-input.mt-0(
            v-model="product.hardcopy_online_free"
            label="Online+Karte free?"
            hide-details
          )

          v-col.pb-0(cols="4" sm="2"): v-text-field(
            :value="product.card_valid_from"
            label="Gültig ab"
            dense
            outlined
            :rules="[ rules.required, rules.valid_date() ]"
            :disabled="!product.product_id"
            @change="onChanageValidFrom"
          )

          v-col.pb-0(cols="8" sm="4")
            v-text-field(
              v-model="product.card_valid_to"
              label="Gültig bis"
              dense
              outlined
              :rules="[ rules.required, rules.valid_date(), !isSuperAdmin ? rules.date_between(product.card_valid_from, maxDate) : true ]"
              :disabled="!product.product_id || !product.card_valid_from"
            )
            label.max-date-hint.admin-view(v-if="isSuperAdmin && product.card_valid_from") Das Datum sollte nach Konfiguration vor dem {{ maxDate }} liegen


          v-col.pb-0(cols="12" sm="6")
            v-select(
              v-model="product.barcode_type"
              label="Barcode-Typ"
              clearable
              outlined
              dense
              hide-details
              :items="barCodes"
              @change="onCheckExternalId"
            )
            v-textarea.mt-3(
              v-if="product.need_external_id"
              v-model="product.barcode_hint"
              :label="`${ externalIdName || `Externe-ID` } Hinweis`"
              rows="3"
              hide-details
              outlined
              dense
            )

          v-col.pb-0(v-if="!isSuperAdmin" cols="12" sm="6")

          v-col(cols="6" sm="3"): v-checkbox.mt-0(
            v-model="product.is_disabled"
            label="Deaktiviert?"
            hide-details
          )
          v-col(v-if="isSuperAdmin" cols="6" sm="3"): v-checkbox.admin-input.mt-0(
            v-model="product.is_test"
            label="Test?"
            hide-details
          )

          v-col(v-if="isSuperAdmin" cols="6" sm="3"): v-checkbox.admin-input.mt-0(
            v-model="product.payment_disabled"
            label="Zahlung deaktiviert"
            hide-details
            :disabled="!!selectedProduct.payment_disabled"
          )

          v-col(v-if="isSuperAdmin" cols="6" sm="3"): v-checkbox.admin-input.mt-0(
            v-model="product.tenant_payment"
            label="Mandaten-Zahlung"
            hide-details
          )

          v-col.pb-0(cols="6" sm="3"): v-checkbox.mt-0(
            v-if="product.product_id && externalIdName"
            v-model="product.need_external_id"
            :label="`${ externalIdName || 'Externe-ID' } benötigt`"
            :disabled="!!product.barcode_type || (selectedProduct.product && selectedProduct.product.needs_external_id)"
            :rules="[ product.barcode_type ? rules.required : true ]"
          )

          v-col.pb-0(cols="6" sm="3"): v-checkbox.mt-0(
            v-if="product.product_id && externalDepartmentName"
            v-model="product.need_external_department"
            :label="`${ externalDepartmentName || 'Externe Abteilung' } benötigt`"
          )
          v-col.pb-0(cols="6" sm="3"): v-checkbox.mt-0(
            v-if="product.product_id"
            v-model="product.hide_city"
            label="Ort verbergen"
          )
          v-col.pb-0(cols="6" sm="3"): v-checkbox.mt-0(
            v-if="product.product_id"
            v-model="product.hide_street"
            label="Strasse verbergen"
          )
          v-col.pb-0(cols="6" sm="3")
            v-checkbox.mt-0(
              v-model="product.with_lable"
              label="Kennzeichen auf Karte?"
            )
            label.max-date-hint Kennzeichnung überhaupt möglich?

          v-col.pb-0(cols="6" sm="3")
            v-text-field.mt-0(
              v-model="product.lable_value"
              label="Kennzeichen Wert?"
              dense
              outlined
              :disabled="!product.with_lable"
              :rules="[ rules.maxLen(2), product.with_lable ? rules.required : true ]"
            )
            label.max-date-hint Wenn angegeben, als Standard übernommen

          v-col.pb-0(cols="6" sm="3")
            v-text-field.mt-0(
              v-model="product.lable_color"
              label="Kennzeichen Farbe?"
              dense
              outlined
              :disabled="!product.with_lable"
              :rules="[ product.with_lable ? rules.required : true ]"
            )
            label.max-date-hint Hex Farbe auf dem Ausweis z.B. #F7AD15


          template(v-if="isVerband && false")
            v-col.pb-0(cols="12" sm="6" md="3"): v-checkbox.mt-0(
              v-model="product.needs_field1"
              :label="`${ field1Desc } benötigt`"
              disabled
            )

            v-col.pb-0(cols="12" sm="6" md="3"): v-checkbox.mt-0(
              v-model="product.needs_field2"
              :label="`${ field2Desc } benötigt`"
              disabled
            )

            v-col.pb-0(cols="12" sm="6" md="3"): v-checkbox.mt-0(
              v-model="product.needs_field3"
              :label="`${ field3Desc } benötigt`"
              disabled
            )

        v-col(v-if="directOrderLink" cols="12")
          label.primary--text
            | <b>Direkter Link zur Bestellung:</b>
            | <a class="ml-2" :href="directOrderLink" target="_blank">{{ directOrderLink }}</a>

            v-tooltip(v-model="showTip" top :open-on-hover="false")
              span Copied!
              template(#activator="{ on, attrs }")
                v-btn.ml-2(
                  icon
                  v-bind="attrs"
                  @click.prevent="onCopyLink"
                )
                  v-icon content_copy

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'

import Urls from '@/router/urls'
import AuthMixin from '@/mixins/auth'
import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    AuthMixin,
    FormMixin
  ],

  components: {
    ProductImage: () => import('@/components/common/product-image')
  },

  watch: {
    product: {
      deep: true,
      handler () {
        this.isUpdated = this.dataLoaded
      }
    },

    id () {
      this.loadProductDetails()
    }
  }
})
export default class ProductDetails extends Vue {
  @Prop({ type: [ Number, String ], default: 0 }) id
  @Prop({ type: Boolean, default: false }) readonly

  product = {
    product_id: 0,
    can_online: true,
    can_hardcopy: true,
    export_unvalidated: true,
    tenant_name: '',
    tenant_name2: '',
    price_online: 0,
    price_hardcopy: 0,
    price_hardcopy_online: 0,
    product_short_code: '',
    is_disabled: false,
    is_test: false,
    card_valid_from: '',
    card_valid_to: '',
    payment_disabled: false,
    need_external_id: false,
    need_external_department: false,
    barcode_type: '',
    barcode_hint: '',
    tenant_payment: false,
    online_free: false,
    hardcopy_free: false,
    hardcopy_online_free: false,
    hide_city: false,
    hide_street: false,
    needs_field1: false,
    needs_field2: false,
    needs_field3: false,
    field1_desc: null,
    field2_desc: null,
    field3_desc: null,
    with_lable: false,
    lable_value: '',
    lable_color: '',
    days_before_renew: 30,
    max_years_valid: 0
  }

  availableProducts = []
  selectedProduct = {}
  productShortCode = ''
  maxYearsValid = 0
  initialCardValidTo = ''
  isVerband = false
  field1Desc = ''
  field2Desc = ''
  field3Desc = ''
  externalIdName = ''
  externalDepartmentName = ''
  dataLoaded = false
  isUpdated = false
  showTip = false

  barCodes = [
    {
      value: 'code_128',
      text: 'Code128'
    },
    {
      value: 'code_2_5_interleaved',
      text: 'Code25 Interleaved'
    },
    {
      value: 'code_39_with_checksum',
      text: 'Code39 mit Chechsumme'
    },
    {
      value: 'code_39_without_checksum',
      text: 'Code39 ohne Chechsummme'
    },
    {
      value: 'code_ean8',
      text: 'EAN-8 (z.B. Littera)'
    },
  ]

  images = [
    {
      id: 'logo',
      label: 'Logo Bild',
      src: '',
      file: null,
      width: 230,
      height: 130,
      needs_file: false
    },
    {
      id: 'stamp',
      label: 'Stempel Bild',
      src: '',
      file: null,
      width: 260,
      height: 205,
      needs_file: false
    },
    {
      id: 'sign',
      label: 'Signum Bild',
      src: '',
      file: null,
      width: 260,
      height: 70,
      needs_file: false
    }
  ]

  get title () {
    if (!this.id) {
      return 'Produkt anlegen'
    }

    return 'Produkt bearbeiten'
  }

  get maxDate () {
    if (!this.product.card_valid_from) {
      return ''
    }

    const maxValidDate = this.getDateFromOffset(this.product.card_valid_from, 'DD.MM.YYYY', this.product.max_years_valid, 'years')
    if (!this.initialCardValidTo) {
      return maxValidDate
    }
    // check if initial card_valid_to is higher than the max valid date
    return this.isAfter(this.initialCardValidTo, maxValidDate)
      ? this.initialCardValidTo
      : maxValidDate
  }

  get directOrderLink () {
    console.log('shortcode:', this.productShortCode)
    console.log('selectedTenant:', this.selectedTenant)
    if (!this.productShortCode) {
      return ''
    }

    const route = this.$router.resolve({
      name: 'ordering',
      params: {
        tenantShortCode: this.selectedTenant.short_code,
        productShortCode: this.productShortCode
      }
    })
    console.log(route)

    return `${ window.location.origin }${ route.href }`
  }

  created () {
    this.dataLoaded = !this.id
    this.isUpdated = !this.id
    this.product.is_test = !!this.selectedTenant.is_test
    this.loadAvailableProducts()
      .then(() => {
        this.loadProductDetails()
      })
  }

  getImageType (image) {
    if (image.id !== 'sign' || !this.isVerband) {
      return image.id
    }
    return 'stamp'
  }

  getImageLabel (id) {
    const defaultLabels = {
      logo: 'Logo Bild',
      stamp: 'Stempel Bild',
      sign: 'Signum Bild',
    }
    if (this.selectedProduct && this.selectedProduct.product_type !== 'Verbandsausweis') {
      return defaultLabels[id] || ''
    }
    const verbandLabels = {
      logo: 'Verbands-Logo',
      stamp: 'Stempel Verein',
      sign: 'Verein Logo',
    }
    return verbandLabels[id] || ''
  }

  getImageWidth (image) {
    if (image.id !== 'sign' || !this.isVerband) {
      return image.width
    }
    const stampImage = this.images.find(im => im.id === 'stamp')
    return stampImage.width
  }

  getImageHeight (image) {
    if (image.id !== 'sign' || !this.isVerband) {
      return image.height
    }
    const stampImage = this.images.find(im => im.id === 'stamp')
    return stampImage.height
  }

  loadProductDetails () {
    if (!this.id) {
      return
    }

    this.$axios
      .get(Urls.Product.Details, {
        id: this.id
      })
      .then(res => {
        this.product = {
          ...res.data,
          tenant_payment: res.data.tenant_payee
        }
        this.product.card_valid_from = this.formatDateTime(this.product.card_valid_from, 'DD.MM.YYYY')
        this.product.card_valid_to = this.formatDateTime(this.product.card_valid_to, 'DD.MM.YYYY')
        this.productShortCode = res.data.product_short_code
        this.maxYearsValid = res.data.max_years_valid || 0
        this.isVerband = res.data.product.product_type === 'Verbandsausweis'
        this.field1Desc = res.data.product.field1_desc || 'Field1'
        this.field2Desc = res.data.product.field2_desc || 'Field2'
        this.field3Desc = res.data.product.field3_desc || 'Field3'

        if (!this.isSuperAdmin) {
          const maxValidDate = this.getDateFromOffset(this.product.card_valid_from, 'DD.MM.YYYY', this.maxYearsValid, 'years')

          if (this.isAfter(this.product.card_valid_to, maxValidDate)) {
            this.initialCardValidTo = this.product.card_valid_to
          }
        }

        if (res.data.product.external_id_name) {
          this.externalIdName = res.data.product.external_id_name
          // this.product.need_external_id = true
        } else {
          this.externalIdName = ''
          // this.product.need_external_id = false
        }

        if (res.data.product.external_department_name) {
          this.externalDepartmentName = res.data.product.external_department_name
          // this.product.need_external_department = true
        } else {
          this.externalDepartmentName = ''
          // this.product.need_external_department = false
        }

        const p = this.availableProducts.find(p_ => p_.product_id === res.data.product.id)
        this.selectedProduct = {
          ...p
        }

        this.images = this.images.map(i => {
          i.needs_file = res.data.product[`needs_${ i.id }`]
          const fileName = i.needs_file
            ? this.product[`${ i.id }_file`]
            : ''
          i.src = fileName ? this.getImagePath(fileName) : ''
          i.file = null
          return i
        })
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
      .finally(() => {
        this.dataLoaded = true
        this.isUpdated = false
      })
  }

  loadAvailableProducts () {
    return this.$axios
      .get(Urls.Product.AvailableProducts)
      .then(res => {
        this.availableProducts = (res.data || [])
          .filter(p => this.isSuperAdmin || p.can_create_tenant_product)
          .map(p => ({
            ...p,
            product_type: p.product.product_type,
            description: p.product.description,
            maxYearsValid: p.product.max_years_valid
          }))
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
  }

  onSaveProduct () {
    if (this.images.filter(image => !image.file && !image.src && image.needs_file).length > 0) {
      this.$toast.error('Please select the image(s).', 'Fehler')
      return
    }
    if (!this.isFormValid('form-product')) {
      return
    }

    const formData = new FormData()

    if (this.id) {
      formData.append('id', this.id)
    }

    formData.append('product_id', this.product.product_id)
    formData.append('can_online', this.product.can_online)
    formData.append('can_hardcopy', this.product.can_hardcopy || false)
    formData.append('export_unvalidated', this.product.export_unvalidated || false)
    formData.append('tenant_name', this.product.tenant_name)
    formData.append('tenant_name2', this.product.tenant_name2 || '')
    formData.append('price_online', this.product.price_online)
    formData.append('price_hardcopy', this.product.price_hardcopy)
    formData.append('price_hardcopy_online', this.product.price_hardcopy_online)
    formData.append('product_short_code', this.product.product_short_code)
    formData.append('is_disabled', this.product.is_disabled)
    formData.append('card_valid_from', this.changeDateFormat(this.product.card_valid_from))
    formData.append('card_valid_to', this.changeDateFormat(this.product.card_valid_to))
    formData.append('payment_disabled', this.product.payment_disabled)
    formData.append('need_external_id', this.product.need_external_id)
    formData.append('need_external_department', this.product.need_external_department)
    formData.append('hide_city', this.product.hide_city)
    formData.append('hide_street', this.product.hide_street)
    formData.append('days_before_renew', this.product.days_before_renew)
    formData.append('max_years_valid', this.product.max_years_valid)

    formData.append('with_lable', this.product.with_lable)
    if (this.product.with_lable){
      formData.append('lable_value', this.product.lable_value)
    }

    if (this.product.with_lable){
      formData.append('lable_color', this.product.lable_color)
    }

    if (this.isSuperAdmin) {
      formData.append('tenant_payment', this.product.tenant_payment)
      formData.append('is_test', this.product.is_test)
      formData.append('online_free', this.product.online_free)
      formData.append('hardcopy_free', this.product.hardcopy_free)
      formData.append('hardcopy_online_free', this.product.hardcopy_online_free)
    }

    if (this.product.barcode_type) {
      formData.append('barcode_type', this.product.barcode_type)
    }

    if (this.product.need_external_id) {
      formData.append('barcode_hint', this.product.barcode_hint || '')
    }

    if (this.isVerband) {
      // formData.append('needs_field1', this.product.needs_field1)
      // formData.append('needs_field2', this.product.needs_field2)
      // formData.append('needs_field3', this.product.needs_field3)
      // formData.append('field1_desc', this.product.field1_desc)
      // formData.append('field2_desc', this.product.field2_desc)
      // formData.append('field3_desc', this.product.field3_desc)
    }

    this.images
      .filter(i => !!i.file && i.needs_file)
      .forEach(i => {
        formData.append(`${ i.id }_file`, i.file)
      })

    this.$axios
      .post(Urls.Product.Upsert, formData, true)
      .then(res => {
        this.isUpdated = false
        this.$toast.success('Daten gespeichert', 'Speichern')
        this.productShortCode = this.product.product_short_code

        if (!this.id) {
          this.$router.push({
            name: 'product-edit',
            params: {
              id: res.data.id
            }
          })
        } else {
          this.images = this.images.map(i => {
            const fileName = res.data[`${ i.id }_file`]
            i.src = fileName ? this.getImagePath(fileName) : ''
            i.file = null
            return i
          })
        }
      })
      .catch(err => {
        this.$toast.error(err.message, 'Fehler')
      })
  }

  onChanageValidFrom (v) {
    this.product.card_valid_from = v
    if (!this.maxDate) {
      return
    }
    // console.log('on change valid from: ', this.product.card_valid_to, this.maxDate)
    if (!this.product.card_valid_to) {
      this.product.card_valid_to = this.maxDate
    } else if (this.isAfter(this.product.card_valid_to, this.maxDate)) {
      this.product.card_valid_to = ''
    }

    this.product = {
      ...this.product
    }
  }

  onSelectImage (index, blob) {
    this.images[index].file = blob
    this.isUpdated = true
  }

  onSelectProduct (v) {
    if (!v) {
      return
    }

    const p = this.availableProducts.find(p_ => p_.product_id === v)
    this.selectedProduct = {
      ...p
    }
    this.product.payment_disabled = p.payment_disabled
    this.product.price_hardcopy = p.product.price_hardcopy
    this.product.price_hardcopy_online = p.product.price_hardcopy_online
    this.product.price_online = p.product.price_online
    this.product.tenant_payment = p.tenant_payment
    this.product.tenant_name = p.tenant_name
    this.product.tenant_name2 = p.tenant_name2
    this.product.needs_field1 = p.product.needs_field1
    this.product.needs_field2 = p.product.needs_field2
    this.product.needs_field3 = p.product.needs_field3
    // this.product.field1_desc = p.product.field1_desc
    // this.product.field2_desc = p.product.field2_desc
    // this.product.field3_desc = p.product.field3_desc

    this.product.max_years_valid = p.max_years_valid
    this.isVerband = p.product.product_type === 'Verbandsausweis'
    this.field1Desc = p.product.field1_desc
    this.field2Desc = p.product.field2_desc
    this.field3Desc = p.product.field3_desc

    if (p.product.external_id_name) {
      this.externalIdName = p.product.external_id_name
      this.product.need_external_id = true
    } else {
      this.externalIdName = ''
      this.product.need_external_id = false
    }

    if (p.product.external_department_name) {
      this.externalDepartmentName = p.product.external_department_name
      this.product.need_external_department = true
    } else {
      this.externalDepartmentName = ''
      this.product.need_external_department = false
    }

    if (!this.id) {
      const hash = this.getHashString()
      this.images.map(image => {
        image.needs_file = p.product[`needs_${ image.id }`]
        if (!image.needs_file) {
          return
        }
        const imageId = `tenant_${ image.id }_file`
        if (p[imageId]) {
          this.$axios
            .get(`/st/t/${ p[imageId] }?${ hash }`, null, true)
            .then(res => {
              image.file = res.data
              image.src = this.getImagePath(p[imageId])
            })
        }
      })
    }

    this.onChanageValidFrom(this.product.card_valid_from)
  }

  onChangeHardCopy (v) {
    this.product.export_unvalidated = v
  }

  onCopyLink () {
    navigator.clipboard.writeText(this.directOrderLink)
    this.showTip = true
    setTimeout(() => {
      this.showTip = false
    }, 2000)
  }

  onCheckExternalId () {
    if (this.product.barcode_type) {
      this.product.need_external_id = true
    }
  }
}
</script>

<style lang="scss">
.border-top {
  border-top: 1px solid #ddd
}

.image-hint {
  top: -2px;
}
</style>
