const Anon = (path) => {
  return `/anon/${ path }/`
}

const Auth = (path) => {
  return `/a/${ path }/`
}

const Tenant = (path) => {
  return `/a/t/${ path }/`
}

const Admin = (path) => {
  return `/a/ad/${ path }/`
}

const SuperAdmin = (path) => {
  return `/a/sad/${ path }/`
}

const Order = (path) => {
  return `/order/${ path }/`
}

export default {
  Auth: {
    Login: Anon('auth/login'),
    Refresh: Auth('auth/refresh'),
    UserData: Auth('auth/get_user_data'),
    SetNewPassword: Auth('set_new_password'),
    ResetPassword: Admin('reset_password'),
    SetPasswordByToken: Anon('auth/set_password_by_token'),
    CheckUserToken: Anon('check_user_token'),
    SetEmailByToken: Anon('auth/set_email_by_token'),
    ForgotPassword: Anon('auth/forgot_password'),
  },

  Dev: {
    AppInfo: Anon('info'),
    SaveError: '/jslog/',
  },

  Register: {
    Registering: Anon('register'),
    RegisterListNew: SuperAdmin('list_registers_new'),
    Filter: SuperAdmin('filter_registers'),
    Answer: SuperAdmin('answer_registers'),
  },

  Tenant: {
    Config: SuperAdmin('tenant/configs'),
    Details: SuperAdmin('tenant'),
    List: SuperAdmin('tenants'),
    Upsert: SuperAdmin('tenant/upsert'),
    Products: SuperAdmin('products'),
    TenantOrderCount: SuperAdmin('tenant/get_order_count'),
    TenantDelete: SuperAdmin('tenant'),
  },

  Product: {
    Details: Admin('tenant/product'),
    List: Admin('tenant/products'),
    Upsert: Admin('tenant/products/upsert'),
    AvailableProducts: Admin('tenant/available_products'),
  },

  Card: {
    List: Tenant('card'),
    GetImage: Tenant('card_image'),
    Validate: Tenant('card/validate'),
    Details: Tenant('card'),
    Update: Tenant('card_update'),
    Delete: Tenant('card'),
    Undelete: Tenant('card_undelete'),
    Info: Anon('card'),
    ResendOnlineCode: Tenant('resend_online_code'),
    DownloadCard: SuperAdmin('download_card'),
    ResetExport: SuperAdmin('reset_export'),
    ExportExcel: Tenant('export_excel'),
    RecreateCard: SuperAdmin('recreate_card'),
  },

  Orders: {
    List: SuperAdmin('filter_orders'),
  },

  InvoiceOrders: {
    List: SuperAdmin('filter_invoiceable_orders'),
    CreatePaymentRun: SuperAdmin('create_payment_run'),
    ListRuns: SuperAdmin('filter_payment_runs'),
    PaymentRunOrders: SuperAdmin('filter_payment_run_orders'),
    Report: SuperAdmin('report')
  },

  User: {
    List: Admin('profiles'),
    Profile: Admin('profile'),
    MyProfile: Tenant('user_profile'),
    RemoveProfile: Tenant('remove_profile_from_tenant')
  },

  Order: {
    ProductInfo: (tcode, pcode) => Order(`${ tcode }/${ pcode }`),
    PlaceOrder: Order('place_order'),
    TestImage: Order('test_image'),
    AddPaypalTransaction: Order('add_paypal_transaction'),
    PaypalCreateOrder: Order('paypal/create_payment'),
    StripeCreateOrder: Order('stripe/create_payment'),
    GetOrderPayed: Order('get_order_payed'),
    StripeCancelOrder: Order('cancel'),
  },
}
