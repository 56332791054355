<template lang="pug">
  v-card.student-card-stu(outlined)
    p.watermark COPY-SAFE
    canvas(width="600" height="380")
    svg(
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 8.5 5.4"
      version="1.1"
    )
      g
        image(
          x="0.3"
          y="0.25"
          width="2.3"
          height="1.3"
          :xlink:href="logoImage"
        )

        image(
          x="0.3"
          y="1.75"
          width="2.3"
          height="3.4"
          :xlink:href="studentImage_"
        )

        image(
          x="5.85"
          y="2.1"
          width="2"
          height="1.58"
          :xlink:href="stampImage"
        )

        image(
          x="5.65"
          y="3.7"
          width="2.6"
          height="0.7"
          :xlink:href="signImage"
        )

        image(
          x="2.9"
          y="4"
          width="2.75"
          height="0.5"
          :xlink:href="require('@/assets/images/barcode-sch.png')"
        )

        template(v-for="(svgText, index) in svgTexts")
          text(
            v-if="!svgText.hidden"
            :key="index"
            :x="svgText.x"
            :y="svgText.y + 0.2"
            alignment-baseline="middle"
            style="text-anchor: start;"
          )
            tspan(
              :fill="svgText.color || '#000000'"
              :font-family="svgText.fontFamily || 'Helvetica'"
              :font-size="svgText.size"
              :font-style="svgText.fontStyle || 'normal'"
              :font-weight="svgText.fontWeight || 'normal'"
            ) {{ svgText.text }}

</template>

<script>
import { Vue, Component, Prop } from 'vue-property-decorator'
import FormMixin from '@/mixins/form'

@Component({
  mixins: [
    FormMixin
  ]
})
export default class StudentCardStu extends Vue {
  @Prop({ type: String, default: '' }) logoImage
  @Prop({ type: String, default: '' }) stampImage
  @Prop({ type: String, default: '' }) signImage
  @Prop({ type: [Blob, String], default: null }) studentImage
  @Prop({ type: Object, default: () => {} }) productInfo
  @Prop({ type: Object, default: () => {} }) studentInfo

  get studentImage_ () {
    if (!this.studentImage) {
      return require('@/assets/images/default-student.jpg')
    }
    if (typeof this.studentImage === 'string') {
      return this.studentImage
    }
    return URL.createObjectURL(this.studentImage)
  }

  get svgTexts () {
    if (!this.productInfo) {
      return []
    }

    const isClubCard = this.productInfo.product.product_type === 'Vereinsausweis'
    const xOffset = 0.05

    return [
      {
        text: this.productInfo.product.card_title,
        color: '#1a176e',
        fontWeight: 'bold',
        x: 2.9 + xOffset,
        y: 0.52,
        size: 0.5,
      },
      {
        text: this.productInfo.product.card_title2,
        color: '#1a176e',
        x: 2.9 + xOffset,
        y: 0.95,
        size: 0.3
      },
      {
        text: `${ this.productInfo.tenant_name } ${ isClubCard ? `\u00A0\u00A0\u00A0${ this.productInfo.tenant_name2 }` : '' }`,
        fontWeight: 'bold',
        x: 2.9 + xOffset,
        y: 1.45,
        size: 0.25
      },
      {
        text: this.productInfo.tenant_name2,
        fontWeight: 'bold',
        x: 2.9 + xOffset,
        y: 1.75,
        size: 0.25,
        hidden: isClubCard
      },
      {
        text: this.productInfo.tenant_street,
        x: 2.9 + xOffset,
        y: this.productInfo.tenant_name2 && !isClubCard ? 2.15 : 1.8,
        size: 0.25
      },
      {
        text: `${ this.productInfo.tenant_plz || '' } ${ this.productInfo.tenant_city || '' }`,
        x: 2.9 + xOffset,
        y: this.productInfo.tenant_name2 && !isClubCard ? 2.5 : 2.15,
        size: 0.25
      },
      // {
      //   text: this.studentInfo.external_department,
      //   x: 2.9,
      //   y: 2.25,
      //   size: 0.2,
      //   hidden: !this.productInfo.need_external_department
      // },
      {
        text: `${ this.productInfo.product.external_id_name || 'ID.Nr.' } ${ this.studentInfo.external_id || '' }`,
        x: 2.9 + xOffset,
        y: 2.9,
        size: 0.25,
        hidden: !this.productInfo.need_external_id
      },
      {
        text: this.studentInfo.forname || 'Mustervorname',
        fontWeight: 'bold',
        x: 2.9 + xOffset,
        y: 3.3,
        size: 0.3
      },
      {
        text: this.studentInfo.familyname || 'Musternachname',
        fontWeight: 'bold',
        x: 2.9 + xOffset,
        y: 3.67,
        size: 0.3
      },
      {
        text: 'Geb.Datum',
        fontWeight: 'bold',
        x: 2.85 + xOffset,
        y: 4.6,
        size: 0.2
      },
      {
        text: this.studentInfo.birthday || 'Geb. Datum',
        x: 4.6,
        y: 4.6,
        size: 0.2
      },
      {
        text: 'Gültig bis',
        fontWeight: 'bold',
        x: 2.85 + xOffset,
        y: 4.95,
        size: 0.2
      },
      {
        text: this.formatDateTime(this.productInfo.card_valid_to, 'DD.MM.YYYY') || '09/2021',
        x: 4.6,
        y: 4.95,
        size: 0.2
      },
      {
        text: this.productInfo.product.card_signer_desc,
        fontStyle: 'italic',
        x: 6.2,
        y: 4.45,
        size: 0.2
      },
    ]
  }
}
</script>

<style lang="sass">
.student-card-stu
  position: relative
  border-radius: 16px
  max-width: 620px
  width: 100%

  svg
    position: absolute
    top: 0
    left: 0
    width: 100%

  canvas
    opacity: 0
    max-width: 100%

  img
    width: 100%
    height: auto

  .card-image
    position: absolute
    width: 100%
    height: auto

    &--logo
      top: 8%
      left: 3%
      max-width: 25%
      max-height: 20%

    &--sign
      top: 69%
      left: 70%
      max-width: 25%
      max-height: 12%

    &--stamp
      top: 23%
      left: 67%
      max-width: 30%
      max-height: 50%
</style>
